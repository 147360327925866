import Swiper, { Autoplay, EffectFade, Navigation, Thumbs } from 'swiper'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import 'swiper/css/thumbs'

Swiper.use([Autoplay, EffectFade, Navigation, Thumbs])

document.querySelectorAll('.element-whymyfirm').forEach((el) => {
  const mainContainer = el.querySelector('.why-firm-content-slider')
  const thumbContainer = el.querySelector('.why-firm-thumbnail-slider')
  const numSlides = mainContainer.querySelectorAll('.swiper-slide').length

  if (numSlides > 1) {
    const thumbSwiper = new Swiper(thumbContainer.querySelector('.swiper'), {
      slidesPerView: 'auto',
      spaceBetween: 10,
      preloadImages: false,
      watchSlidesProgress: true,
      threshold: 20,
    })

    new Swiper(mainContainer.querySelector('.swiper'), {
      slidesPerView: 1,
      speed: 1000,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      preloadImages: false,
      loop: true,
      loopedSlides: numSlides,
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
      navigation: {
        nextEl: el.querySelector('.slider-control-next'),
        prevEl: el.querySelector('.slider-control-prev'),
      },
      thumbs: {
        multipleActiveThumbs: false,
        swiper: thumbSwiper,
      },
    })
  }
})
